import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {environment} from '../../environments/environment';
import {Convertable, FilesUtilityService} from './files-utility.service';
import {isPlatformServer} from "@angular/common";
import {LocalTake, LocalTakeVideo} from "../interfaces/local-take.interface";

@Injectable({
    providedIn: 'root',
})
export class LocalTakeHelperService {

    private readonly _TAKES_DB_NAME = "local_takes";
    private readonly _TAKES_COLLECTION_NAME = "takes";
    private readonly _TAKE_VIDEOS_COLLECTION_NAME = "videos";
    private readonly _CONVERTABLE_TAKES_COLLECTION_NAME = "convertables";

    private _database: IDBDatabase;

    constructor(
        private filesUtilityService: FilesUtilityService,
        @Inject(PLATFORM_ID) private platformId: object
        /* private databaseProviderService: DatabaseProviderService */
    ) {

    }

    initialize = async () => {
        return this._openDatabase();
    }

    getAll = (storyId: string): Promise<LocalTake[]> => {
        return new Promise((resolve, reject) => {
            const index = this._database
                .transaction(this._TAKES_COLLECTION_NAME)
                .objectStore(this._TAKES_COLLECTION_NAME)
                .index("storyId");

            if (index) {
                const data = [];
                index.openCursor(storyId).onsuccess = event => {
                    const cursor = (<IDBRequest<IDBCursorWithValue>>event.target).result;
                    if (cursor) {
                        data.push(cursor.value);
                        cursor.continue();
                    } else {
                        resolve(data);
                    }
                }
            }
        });
    }

    getVideo = (takeId: number): Promise<LocalTakeVideo> => {
        return new Promise((resolve, reject) => {
            if (!takeId) {
                reject("Missing take id");
            }
            const request = this._database
                .transaction(this._TAKE_VIDEOS_COLLECTION_NAME)
                .objectStore(this._TAKE_VIDEOS_COLLECTION_NAME)
                .get(takeId);

            request.onsuccess = event => resolve((<any>event.target).result);
        });
    }

    getAllVideos = (): Promise<LocalTakeVideo[]> => {
        return new Promise((resolve, reject) => {
            const transaction = this._database.transaction(this._TAKE_VIDEOS_COLLECTION_NAME, 'readonly');
            const objectStore = transaction.objectStore(this._TAKE_VIDEOS_COLLECTION_NAME);
            const request = objectStore.getAll();

            request.onsuccess = (event) => {
                const videos = (<any>event.target).result;
                resolve(videos || []); // Return an empty array if videos is null or undefined
            };
            request.onerror = (event) => reject((<any>event.target).error);
        });
    }


    save = (storyId: string, file: Blob, duration: number, fileName: string, isAnonymousTake : boolean = false): Promise<LocalTake> => {
        return new Promise(async (resolve, reject) => {

            const thumbnail = await this.filesUtilityService.createVideoThumbnail(file);

            const transaction = this._database.transaction([this._TAKES_COLLECTION_NAME, this._TAKE_VIDEOS_COLLECTION_NAME], "readwrite");
            const takes = transaction.objectStore(this._TAKES_COLLECTION_NAME);

            const newTake : LocalTake = {
                storyId,
                created: new Date().toISOString(),
                duration,
                thumbnail,
                isAnonymousTake : isAnonymousTake
            }

            const takesRequest = takes.add(newTake);

            takesRequest.onsuccess = event => {
                const takeId = (<any>event.target).result;
                const videos = transaction.objectStore(this._TAKE_VIDEOS_COLLECTION_NAME);
                const videosRequest = videos.add({
                    takeId,
                    fileName,
                    file
                });

                videosRequest.onsuccess = event => {
                    const id = (<any>event.target).result;
                    const takeWithId = { id, ...newTake };
                    resolve(takeWithId);
                };
            }

        });
    }

    changeTakeName = (takeId, newName) => {
        return new Promise((resolve, reject) => {
            if (!takeId) {
                reject("Missing take id");
            }

            const transaction = this._database.transaction(
                this._TAKES_COLLECTION_NAME,
                "readwrite"
            );

            const objectStore = transaction.objectStore(this._TAKES_COLLECTION_NAME);

            const getRequest = objectStore.get(takeId);

            getRequest.onsuccess = (event) => {
                const existingTake = (<any>event.target).result;

                if (existingTake) {
                    // If the take exists, update its name field
                    if ('name' in existingTake) {
                        existingTake.name = newName;
                    } else {
                        // If the name field doesn't exist, add it
                        existingTake.name = newName;
                    }

                    const updateRequest = objectStore.put(existingTake);

                    updateRequest.onsuccess = () => resolve(existingTake);
                    updateRequest.onerror = () => reject("Failed to update take name");
                }else{
                    resolve(false);
                }
            };

            getRequest.onerror = () => reject("Failed to get take");
        });
    }


    delete = (id: number) => {
        return new Promise((resolve, reject) => {
            if (!id) {
                reject("Missing id");
            }
            const takeDeleteRequest = this._database
                .transaction(this._TAKES_COLLECTION_NAME, "readwrite")
                .objectStore(this._TAKES_COLLECTION_NAME)
                .delete(id);

            takeDeleteRequest.onsuccess = _event => {
                const videoDeleteRequest = this._database
                    .transaction(this._TAKE_VIDEOS_COLLECTION_NAME, "readwrite")
                    .objectStore(this._TAKE_VIDEOS_COLLECTION_NAME)
                    .delete(id)

                videoDeleteRequest.onsuccess = event => resolve((<any>event.target).result)
            };
        });
    }

    saveConvertable = (takeId: number, file: File | Blob, fileName: string): Promise<Event> => {
        return new Promise((resolve, reject) => {
            const transaction = this._database.transaction(
                this._CONVERTABLE_TAKES_COLLECTION_NAME,
                "readwrite"
            );
            const objectStore = transaction.objectStore(this._CONVERTABLE_TAKES_COLLECTION_NAME);

            const entry = { takeId, file, fileName };
            const putRequest = objectStore.put(entry);

            putRequest.onsuccess = (event) => {
                resolve(event);
            };

            putRequest.onerror = (event) => {
                reject(event);
            };
        });
    };


    getConvertable = (takeId: number): Promise<Convertable> => {
        return new Promise((resolve, reject) => {
            if (!takeId) {
                reject("Missing take id");
            }
            const request = this._database
                .transaction(this._CONVERTABLE_TAKES_COLLECTION_NAME)
                .objectStore(this._CONVERTABLE_TAKES_COLLECTION_NAME)
                .get(takeId);

            request.onsuccess = event => {
                resolve((<any>event.target).result);
            };

            request.onerror = (event) => {
                reject(event);
            };
        });
    }

    deleteConvertable = (takeId: number): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (!takeId) {
                reject("Missing id");
            }
            const takeDeleteRequest = this._database
                .transaction(this._CONVERTABLE_TAKES_COLLECTION_NAME, "readwrite")
                .objectStore(this._CONVERTABLE_TAKES_COLLECTION_NAME)
                .delete(takeId);

            takeDeleteRequest.onsuccess = event => {
                resolve((<any>event.target).result);
            };

            takeDeleteRequest.onerror = (event) => {
                reject(event);
            };
        });
    }

    private _openDatabase = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (isPlatformServer(this.platformId) || !window.indexedDB) {
                // TODO: reject prevents the app from continue the initialization
                console.log("Your browser doesn't seem to support IndexedDB. Some features will not work")
                resolve();
            } else {
                const { version } = environment.localTakesDatabase;
                const openRequest = window.indexedDB.open(this._TAKES_DB_NAME, version);

                openRequest.onupgradeneeded = event => {
                    this._database = (<IDBOpenDBRequest>event.target).result;
                    this._database
                        .createObjectStore(this._TAKES_COLLECTION_NAME, { keyPath: "id", autoIncrement: true })
                        .createIndex("storyId", "storyId", { unique: false });

                    this._database
                        .createObjectStore(this._TAKE_VIDEOS_COLLECTION_NAME, { autoIncrement: true });

                    this._database
                        .createObjectStore(this._CONVERTABLE_TAKES_COLLECTION_NAME, { keyPath: "takeId", autoIncrement: true });
                }

                openRequest.onsuccess = event => {
                    if (!this._database) {
                        this._database = (<IDBOpenDBRequest>event.target).result;
                    }

                    this._database.onerror = this._handleError;

                    resolve();
                }
            }
        });
    }

    private _handleError = (event: Event) => {
        console.error(event);
    }
}