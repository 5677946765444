{
  "name": "bigvu-frontend",
  "version": "2.30.5",
  "versionCode": 168,
  "description": "BIGVU Frontend",
  "private": true,
  "scripts": {
    "ng": "ng",
    "ng-serve": "ng serve",
    "test": "karma start karma.conf.js",
    "start": "node index.mjs -e local -z true",
    "start:local:dev": "node index.mjs -e local -z false",
    "start:production": "gulp clean:build --production && node index.mjs -e production -z true",
    "start:dev:light": "gulp clean:build:dev:light && node index.mjs -e local -z false",
    "start:dev": "gulp clean:build:dev && node index.mjs -e local -z false",
    "setup:production": "gulp clean:build --production",
    "setup:develop": "gulp clean:build"
  },
  "repository": {
    "type": "git",
    "url": "https://git-codecommit.us-east-1.amazonaws.com/v1/repos/bigvu-frontend"
  },
  "authors": [],
  "license": "BSD-2-Clause",
  "dependencies": {
    "@angular/ssr": "^17.0.1",
    "@sentry/angular": "^8.9.2",
    "@types/accept-language-parser": "^1.5.6",
    "@types/async": "^3.2.24",
    "@types/cookie-parser": "^1.4.6",
    "@types/franc": "^5.0.3",
    "@types/geoip-lite": "^1.4.4",
    "@types/http-proxy-middleware": "^1.0.0",
    "@types/intro.js": "^5.1.1",
    "@types/request-ip": "^0.0.41",
    "accept-language-parser": "^1.5.0",
    "argv": "0.0.2",
    "async": "^3.1.0",
    "axios": "^1.6.8",
    "body-parser": "^1.19.0",
    "chart.js": "^4.3.0",
    "chartjs-plugin-datalabels": "^2.2.0",
    "compression": "^1.7.4",
    "cookie-parser": "^1.4.4",
    "express": "^4.18.2",
    "franc": "^6.2.0",
    "geoip-lite": "^1.4.7",
    "http-proxy-middleware": "^2.0.6",
    "i18n-iso-countries": "^7.6.0",
    "intro.js": "^7.0.1",
    "jszip": "^3.10.1",
    "langs": "^2.0.0",
    "moment": "^2.24.0",
    "moment-timezone": "^0.5.45",
    "ng2-charts": "^4.1.1",
    "request-ip": "^3.3.0",
    "sharp": "^0.33.3",
    "winston": "^3.11.0"
  },
  "devDependencies": {
    "-": "^0.0.1",
    "@amplitude/analytics-browser": "^2.3.5",
    "@angular-devkit/build-angular": "^17.0.0",
    "@angular-devkit/schematics": "^17.0.0",
    "@angular-material-components/datetime-picker": "^16.0.1",
    "@angular/animations": "^17.0.0",
    "@angular/cdk": "^17.0.0",
    "@angular/cli": "^17.0.0",
    "@angular/common": "^17.0.0",
    "@angular/compiler": "^17.0.0",
    "@angular/compiler-cli": "^17.0.0",
    "@angular/core": "^17.0.0",
    "@angular/fire": "^17.0.1",
    "@angular/forms": "^17.0.0",
    "@angular/localize": "^17.0.0",
    "@angular/material": "^17.0.0",
    "@angular/platform-browser": "^17.0.0",
    "@angular/platform-browser-dynamic": "^17.0.0",
    "@angular/platform-server": "^17.0.0",
    "@angular/router": "^17.0.0",
    "@angular/ssr": "^17.0.0",
    "@angular/upgrade": "^17.0.0",
    "@ffmpeg/core": "~0.10.0",
    "@ffmpeg/ffmpeg": "~0.10.1",
    "@ngx-translate/core": "^15.0.0",
    "@popperjs/core": "^2.11.6",
    "@schematics/angular": "^17.0.0",
    "@swimlane/ngx-charts": "^20.4.0",
    "@tensorflow-models/body-pix": "^2.2.0",
    "@tensorflow/tfjs": "^3.11.0",
    "@tinynodes/ngx-editorjs": "^4.0.0",
    "@types/angular": "^1.8.8",
    "@types/d3": "^7.0.0",
    "@types/express": "^4.17.21",
    "@types/gapi": "0.0.39",
    "@types/google-libphonenumber": "^7.4.30",
    "@types/jquery": "^3.3.33",
    "@types/jqueryui": "^1.12.1",
    "@types/lodash": "^4.14.158",
    "@types/mixpanel-browser": "^2.35.7",
    "@types/node": "^16.18.79",
    "@types/socket.io-client": "^1.4.32",
    "@types/underscore": "^1.9.4",
    "@types/video.js": "^7.3.42",
    "@xiaoyexiang/videojs-resolution-switcher-v7": "^1.1.9",
    "angular-draggable-droppable": "^8.0.0",
    "angular2-draggable": "^16.0.0",
    "components-font-awesome": "^5.0.6",
    "core-js": "^3.4.1",
    "country-to-currency": "^1.0.6",
    "cron-parser": "^4.5.0",
    "cronstrue": "^2.11.0",
    "cropperjs": "^1.5.7",
    "crypto-js": "^4.1.1",
    "d3": "^7.2.0",
    "del": "^5.1.0",
    "dom-to-image": "^2.6.0",
    "firebase": "^10.8.0",
    "flag-icons": "^6.6.6",
    "font-awesome": "^4.7.0",
    "google-libphonenumber": "^3.2.22",
    "gulp": "^4.0.2",
    "gulp-clean": "^0.4.0",
    "gulp-gzip": "^1.4.2",
    "gulp-replace": "^1.1.4",
    "hammerjs": "^2.0.8",
    "ignore-loader": "^0.1.2",
    "immer": "^9.0.2",
    "jasmine-core": "^3.5.0",
    "jquery-ui": "^1.12.1",
    "jquery-ui-dist": "^1.12.1",
    "jshint": "^2.10.2",
    "jshint-stylish": "^2.2.1",
    "karma": "^6.4.1",
    "karma-jasmine": "^2.0.1",
    "languagedetect": "^2.0.0",
    "lodash": "^4.17.19",
    "lottie-web": "^5.7.4",
    "main-bower-files": "^2.13.3",
    "material-community-components": "^13.0.0",
    "material-design-iconic-font": "^2.2.0",
    "medium-editor": "^5.23.3",
    "mixpanel-browser": "^2.42.0",
    "ng-croppie": "^1.0.0",
    "ng-dialog": "^1.4.0",
    "ng-infinite-autocomplete": "^3.2.0",
    "ng-mat-select-infinite-scroll": "^4.0.0",
    "ng-tags-input": "^3.2.0",
    "ngx-color-picker": "^14.0.0",
    "ngx-cookie-service": "^16.0.0",
    "ngx-device-detector": "^6.0.1",
    "ngx-dropzone": "^3.1.0",
    "ngx-image-cropper": "^7.0.0",
    "ngx-lottie": "^10.0.0",
    "ngx-pagination": "^6.0.3",
    "ngx-slick-carousel": "^15.0.0",
    "ngx-slider-v2": "^16.0.1",
    "ngx-spinner": "^16.0.2",
    "ngx-stripe": "^16.1.1",
    "node-waves": "^0.7.5",
    "popper.js": "^1.16.1",
    "q": "^2.0.3",
    "reflect-metadata": "^0.1.13",
    "rxjs": "~7.8.0",
    "socket.io-client": "^2.3.0",
    "spin.js": "^4.0.0",
    "terser-webpack-plugin": "^5.3.5",
    "tslib": "^2.4.0",
    "typescript": "~5.2.2",
    "underscore": "^1.9.1",
    "video.js": "^7.18.1",
    "videojs-landscape-fullscreen": "^11.1.0",
    "webpack": "^5.89.0",
    "yargs": "^14.2.0",
    "zone.js": "~0.014.2"
  },
  "engines": {
    "node": "^18.13.0"
  }
}
